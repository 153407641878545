import React from "react"
import Header from "../components/header/header.jsx"
import Footer from "../components/footer/footer.jsx"
import Portfolio from "../components/portfolio/portfolio.jsx"
function Index(){
    return (
        <div>
            <Header />
            
            
            {/*<AnimatedRect width="100vw" height="100vh" Elements={() => {return (<h1>Hello!</h1>)}} />*/}
            <Portfolio/>
            <Footer/>
        </div>

    )
    
}
export default Index;