import React from "react"
import AnimBackground from "../../../components/animBackground/animBackground.jsx"
import Scroller from "../../../components/scroller/scroller.jsx"
import "./index_section1.css"
export default function IndexSection1(){
    return (
        <div className="section1">
            <AnimBackground />
            <div className="parallax container">
                <div className="intro-sec1-wrapper">
                    <div className="intro-sec1 row">
                        <h1>Hi, I'm Shane.</h1>
                        <div className="intro-row">
                            <h3>I'm a {new Date().getFullYear() - 2007} year old</h3>
                            <Scroller text={["student", "programmer", "tinkerer"]} width="200px" style={{}}/>
                        </div>
                    </div>
                </div>
                <div className="intro-sec2 intro-sec row">
                    <div className="row about-me">
                        <div className="col-md-6 col-sm-12 about-me-header">
                            <h1>About This Website:</h1>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="about-me-content">
                                <p>
                                    <span>I'm</span> a {new Date().getFullYear() - 2007} year old student at a secondary school in Singapore.<br/>
                                    <br/>
                                    <b>I love to create things.</b> <br/>
                                    <br/>
                                   
                                    This is probably why when I first picked up a programming book {new Date().getFullYear() - 2017} years ago, I was drawn to the idea of being able to create things on the internet.<br/>
                                    The idea that even a kid, could carve out a small space for himself on the internet to build whatever his heart desired, enthralled me.<br/>
                                    7 years later, after numerous learning experiences and countless hours scouring Stack Overflow, I could finally bring my dream of creating a website that was uniquely mine to fruition.<br/>
                                    Along the way, I have also created numerous projects and learned many skills.<br/>
                                    <br/>
                                    Though I have come some ways from where I was {new Date().getFullYear() - 2017} years ago, I trust that my journey has only just begun.<br/>
                                    Thanks for visiting my spot on the internet! I hope you like it as much as I enjoyed making it.
                                    
                                </p>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="intro-sec3 intro-sec row">
                    <div className="row about-me">
                        <div className="col-md-6 col-sm-12 about-me-header">
                            <h1>Skills:</h1>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="about-me-content">
                                <p>
                                    Here are some of the skills I picked up during the last few years:<br/>
                                    <b>Programming Languages</b>
                                    <ul>
                                        <li>Javascript</li>
                                        <li>CSS</li>
                                        <li>HTML</li>
                                        <li>Python</li>
                                        <li>Bash</li>
                                        </ul>
                                    <b>Libraries</b>
                                    <ul>
                                        <li>Axios</li>
                                        <li>HTML Canvas API</li>
                                        <li>Basic three.js</li>
                                        <li>Basic Tensorflow + Keras (python)</li>
                                        <li>Discord.js</li>
                                        <li>React.js</li>
                                        <li>Basic Puppeteer (Javascript)</li>
                                        </ul>
                                    <b>Platforms</b>
                                    <ul>
                                        <li>Basic Google Cloud</li>
                                        <li>AWS EC2</li>
                                        <li>Wordpress</li>
                                        <li>Node.js</li>
                                    </ul>
                                    <b>Other unrelated skills I picked up:</b>
                                    <ul>
                                        <li>Basic 3D Modelling</li>
                                        <li>3D Printer Operation/Maintenance</li>
                                        <li>Video Editing</li>
                                        <li>Basic soldering/electronic work</li>
                                        
                                    </ul>

                                    
                                </p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}