import React from "react"
import Header from "../components/header/header.jsx"
import Footer from "../components/footer/footer.jsx"
import AnimatedRect from "../components/animatedRect/animatedRect.jsx"
import AnimBackground from "../components/animBackground/animBackground.jsx"
import Scroller from "../components/scroller/scroller.jsx"
import IndexSection1 from "./sections/index_section1/index_section1.jsx"
function Index(){
    return (
        <div>
            <Header />
            
            
            
            <IndexSection1 />
            <AnimatedRect width="100vw" height="100vh" Elements={() => {return (<h1>Hello!</h1>)}} />
            <Footer/>
           

        </div>

    )
    
}
export default Index;