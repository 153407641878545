import React from "react"
import ReturnComponent from "../return.jsx"
import "./scroller.css"
export default function Scroller(props){
    var uniqueID = Math.floor(Math.random() * 1000000)
    var spacing = 60;
    var elms = []
    
    for(var textNo in props.text){
        var key = Math.floor((Math.random() * 10000))
        //console.log(linkData)
        
        elms.push(<ReturnComponent elm={(<li id={`scroller-elm${uniqueID}/${textNo}`} className="scroller-li" key={key} ><p style={{width: (props.width)}}>{props.text[textNo]}</p></li>)}/>)
    }
    React.useEffect(() => {
        var elmDB = []
        for(var i in props.text){
            var elm = document.getElementById(`scroller-elm${uniqueID}/${i}`)
            elmDB.push({element: elm, x: 0})
            elm.style.setProperty("left", (props.width * textNo) + "px") 
            
        }
            
            var incoming = elmDB[0];
            //console.log(incoming)
           // console.log("incoming")
            
            incoming.element.style.setProperty("left", `0px`)
            
        setInterval(() => {
            //first element goes out
            //second element goes in
            //last element gets transported back
            //console.log(elmDB)
            
            
            var outgoing = elmDB.shift();
            //console.log(props.width)
            //console.log("firstelm")
            outgoing.element.style.setProperty("left", `-${props.width}`)
            //console.log(outgoing.element.style.getPropertyValue("left"))
            
            
            var storedElm = elmDB[elmDB.length - 1];
            
            storedElm.element.style.setProperty("display", `none`)
            storedElm.element.style.setProperty("left", props.width)
            
            elmDB.push(outgoing)
            var incoming = elmDB[0];
            
            
            incoming.element.style.setProperty("left", `0px`)
            setTimeout(() => {
                storedElm.element.style.setProperty("display", `inline-block`)
            }, 500)
        }, 2000)
    }, [uniqueID])
    return (
        <div className="scroller" id={`parent${uniqueID}`} style={{width: props.width, ...props.style}}>
            <ul className="scroller-ul" id={`parent${uniqueID}`}>
                {elms}
                
            </ul>
        </div>
    )
}