import React from "react"
import "./header.css"
function Header() {
 
return (
    <div className="header-react-container">
    

<header>

    <div className="header container-fluid">
        <div className="">
            <div className="header-button">
                <div id="menuToggle">
                <input type="checkbox" />
                    <span></span>
                    <span></span>
                    <span></span>
                    
                    <ul id="header-menu">
                      <a href="/"><li>Home</li></a>
                      <a href="/portfolio"><li>Portfolio</li></a>
                      <a href="/bookmeacourt/"><li>Book Me A Court</li></a>
                      
                      {/*<a href="/dashboard.html"><li>Dashboard</li></a>*/}
                      
                    </ul>
                  </div>
            </div>
            <div className="header-title-box">
                <h2 className="header-title">Shane Yeo</h2>
            </div>
            
        </div>
    </div>
</header>
<div style={{display: "block", height: "7.5vh", minHeight: "54px"}}></div>
</div>

)

}
export default Header