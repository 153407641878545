import React from "react"
import "./portfolio.css"
import PortfolioElement from "./portfolioElement"
const trianglify = require("trianglify")
const content = require("../../content/portfolio.json")
//import "/images/main1.jpg"
export default function Portfolio(props){
    var uniqueID = Math.floor(Math.random() * 1000000)
    var elements = []
    console.log(content)
    let sortable = [];
    for (var entr in content) {
        sortable.push([entr, content[entr].date]);
    }

    sortable.sort(function(a, b) {
        return a[1] - b[1];
    });
    
    for(var enNo in sortable){
      //console.log(sortable[enNo][0])
      var entry = content[sortable[enNo][0]]
      elements.push((
        <>
          
          <PortfolioElement title={entry.title} humanDate={entry.humanDate}  desc={entry.desc} skills={entry.skills} position={(enNo % 2 === 0) ? "left" : "right"} links={entry.links} key={enNo}/>
        </>
      ))
    }
    
    React.useEffect(() => {
        //setup content
        
        
         //how far the point can move per iteration in any given direction from point of origin
        let canvas = document.getElementById("canvasbg")
        let limiter = 0.03
        let movementLimit = 5;
        //how long it takes for a current path to expire in iterations
        let timeout = 10;
        let colors = []
        //console.log("execution")
        var options = {
            width: window.innerWidth,
            height: window.innerHeight * 2,
            cellSize: 150,
            variance: 0.9,
            seed: "hehe",
            xColors: 'Blues',
            yColors: "match",
            fill: true,
            
            colorFunction: trianglify.colorFunctions.interpolateLinear(),
            strokeWidth: 0,
            points: null
          }
          
          var pattern = trianglify(options)
          pattern.toCanvas(canvas)
          var pointDB = pattern.points
          //console.log(pointDB[0])
          var pointMetaDB = []
          var x = 0;
          while(x < pattern.points.length){
            pointMetaDB.push(generatePath())
            x++;
          }

          function animate(){
            //modify points
            for(var arrNo in pointDB){
              var pointData = pointMetaDB[arrNo]
              var newX = pointDB[arrNo][0] + pointData.movements[0]
             
              /*if(arrNo == 0){
                console.log(pointData.movements)
                console.log("newX" + newX)
                console.log("before" + pointDB[0])
              }*/
              if(newX < window.innerWidth){
                
                pointDB[arrNo][0] = newX;
              }
              
              var newY = pointDB[arrNo][1] + pointData.movements[1]
              if(newX < window.innerWidth){
                pointDB[arrNo][1] = newY;
              }
              //Refresh movements if timeout is over
              if(pointData.timeout <= 0){
                pointMetaDB.splice(arrNo, 1, generatePath()) 
                               
              }
              pointData.timeout -= 1;
            }
            //console.log("after" + pointDB[0])
            options.points = pointDB;
            pattern=trianglify(options)
            pattern.toCanvas(canvas)

            setTimeout(() => {requestAnimationFrame(animate)}, 10)
          }
          animate()
          //util functions
          function generatePath(){
            return {
                movements: [((random(movementLimit * 2 + 1) - movementLimit) * limiter), ((random(movementLimit * 2 + 1) - movementLimit) * limiter)],
                timeout: random(timeout) + 200
            }
          }
          function random(limit){
            return Math.floor(Math.random() * limit)
          }
          

        
    }, [])
    /*React.useEffect(() => {
      let lastKnownScrollPosition = 0;
      let ticking = false;
      let canvas = document.getElementById("canvasBar")
      var ctx = canvas.getContext("2d")
      canvas.height = window.screen.height;
      canvas.width = window.screen.width / 10;
      const vw = canvas.width / 100;
      const vh = canvas.height / 100;
      function render(){
        ctx.beginPath()
        ctx.arc(50 * vw, 10 * vh, 50 * vw, 0, 2 * Math.PI)
      }
      function doSomething(scrollPos) {
        // Do something with the scroll position
      }

      document.addEventListener("scroll", (event) => {
        lastKnownScrollPosition = window.scrollY;

        if (!ticking) {
          window.requestAnimationFrame(() => {
            doSomething(lastKnownScrollPosition);
            ticking = false;
          });

          ticking = true;
        }
      });
    }, [])*/
    return (
        <div className="portfolio-wrapper">
          <div className="intro">
            <div className = "intro-text">
              <h1>Portfolio</h1>
              <p>Coding has always been a longtime hobby of mine, which started {(new Date()).getFullYear() - 2016} years ago when I picked up a kid's programming book off a library shelf. <br/><br/>Since then, I have continued to work on many projects. The ones complete enough to be displayed are shown here.<br/><br/> These are all my notable achievements so far, organised by date.<br/> There are links provided as well for your reference.</p>
            </div>
          </div>
          <div className="bg-elements">
            <canvas id="canvasbg"></canvas>
            
          </div>
            

            <div className="portfolio-content parallax">
              {elements}
              
            </div>
        </div>
    )
}
function timelineElement(side){
  if(side === "left"){
    return (
      <canvas class="canvasLeft"></canvas>
    )
  } else {
    return (
      <canvas class="canvasRight"></canvas>
    )
  }

}