import React from "react"
import "./portfolio.css"
import ReturnComponent from "../return.jsx"

export default function PortfolioElement(props){
    
        var elms = [];
        //console.log(typeof props.links)
        if(typeof props.links === "object" && props.links.length !== 0){
            elms.push(<ReturnComponent elm = {(<span key="start">Links: </span>)}/>)
        }
        for(var linkData in props.links){
            var key = Math.floor((Math.random() * 10000))
            //console.log(linkData)
            elms.push(<ReturnComponent elm={(<a href={props.links[linkData].link} key={key}  target="_blank" rel="noreferrer">{props.links[linkData].title}</a>)}/>)

        }
    
    
   // console.log(elms)
   
    //console.log(content)
    
    return (
        <div className="portfolio-element-boundary">
            <div className={`portfolio-element-wrapper portfolio-element-wrapper-${props.position}`}>
                <div className="portfolio-element-top">
                    <div className="portfolio-element-title">
                        {props.title}
                    </div>
                    
                    <div className="portfolio-element-date">
                        {props.humanDate}
                    </div>
                    <hr/>
                    
                </div>
                <div className="portfolio-element-bottom">
                    <div className="portfolio-element-desc">
                        {props.desc}
                    </div>
                    <div className="portfolio-element-skills">
                        <span>Skills: </span>{props.skills}
                    </div>
                    <div className="portfolio-element-links">
                        
                        {elms}
                    </div>
                </div>
        </div>
    </div>
    )
}