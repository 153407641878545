import React from "react"
import Header from "../components/header/header.jsx"
import Footer from "../components/footer/footer.jsx"
import DashboardComponent from "../components/dashboard/dashboard.jsx"
function Dashboard(){
    return (
        <div>
            <Header />
            <DashboardComponent />
            <Footer/>
           

        </div>

    )
    
}
export default Dashboard;