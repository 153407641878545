import React from "react"
import "./animBackground.css"
export default function AnimBackground(props){
    var uniqueID = Math.floor(Math.random() * 1000000)
    React.useEffect(() => {
       // window.addEventListener("DOMContentLoaded", () => {
            var headerText = document.getElementsByClassName("header-title")[0]
            var canvas = document.getElementById(`canvas${uniqueID}`)
            
            canvas.width = window.innerWidth;
            canvas.height = (window.innerHeight / 100 * 125);
            var ctx = canvas.getContext("2d");
            var width = canvas.width;
            //console.log(width)
            var height = canvas.height;
            var vw = canvas.width / 100;
            var vh = canvas.height / 100;
            //config options
            var columnSpacing = 60;
            var rowSpacing = 50;
            var fps = 100000;
            ctx.lineWidth = 10;
            ctx.lineJoin = "round";
            //out of 1000
            var spawnChance = 1;
            //(x)px per iteration
            var progression = 2;
            // gather start points
            //var noRows = Math.ceil(height / rowSpacing)
            var buffer = {x: 2000, y: 70}
            //var noColumns = Math.ceil((width + buffer.x) / columnSpacing) 
            
            var startXPos = [];
            var startYPos = [];
            var total = -1 * buffer.x;
            //populate array with start coords
            while(total < width){
                startXPos.push(total)
                total += columnSpacing * 2;
            } 
            total = -1 * buffer.y;
            //populate array with start coords
            /*while(total < height){
                console.log(total)
                startYPos.push(total)
                total += (rowSpacing * 4);
            }*/
            
            var gradientsData = {x: {}, y: {}};
            //populate the gradient database with the parent object for each group
            for(var groupCoord of startXPos){
                gradientsData.x[groupCoord] = {}
            }
           /* for(groupCoord of startYPos){
               gradientsData.y[groupCoord] = {}
            }*/
            ctx.fillStyle = "black"
                ctx.fillRect(0, 0, width, height)
            for(var groupCoord of startXPos){
                    
                   // console.log(gradientsData)
                    //console.log(startXPos)
                    generateLine(groupCoord, -1 * buffer.y, ctx, width, height, columnSpacing, rowSpacing, buffer, gradientsData)
                }
            const animate = () => {
                
                // When the component is unmounted, we will lose the canvas.current ref, so exit early to clean up
                
                for(groupCoord of startXPos){
                    if(Math.floor(Math.random() * 1000) <= spawnChance){
                        //Spawn a gradient
                        gradientsData.x[groupCoord][uniqueIDGen()] = {
                            y: -1 * buffer.y,
                            coloring: true,
                            seed: Math.floor(Math.random() * 60)
                        }
                        
                        //console.log(gradientsData.x)
                    }
                   
                    gradientManager(groupCoord, -1 * buffer.y, ctx, width, height, columnSpacing, rowSpacing, buffer, gradientsData)
                }
               /* for(groupCoord of startYPos){
                    if(Math.floor(Math.random() * 1000) <= spawnChance){
                        //Spawn a gradient
                        gradientsData.y[groupCoord][uniqueIDGen()] = {
                            x: -1 * buffer.x
                        }
                    }

                    //console.log(startXPos)
                    generateLine(-1 * buffer.x, groupCoord, ctx, width, height, columnSpacing, rowSpacing, buffer, gradientsData)
                }
*/
                //ctx.fillStyle = gradient;
                //ctx.fillRect(200, 200, 200, 200)
                //ctx.beginPath();
                /*var cXX = 500;
                console.log(xCalculator(880, cXX, width, height, columnSpacing, rowSpacing, buffer))
                ctx.arc(xCalculator(880, cXX, width, height, columnSpacing, rowSpacing, buffer), cXX, 10, 0, 2 * Math.PI);
                ctx.strokeStyle = "rgba(167, 168, 167, 1)";
                ctx.stroke();*/
                
                setTimeout(() => {
                    //requestAnimationFrame(animate);
                }, 1000 / fps);
               requestAnimationFrame(animate);
            }
            function gradientManager(gxC, gyC, ctx, width, height, columnSpacing, rowSpacing, buffer, gradients){
                for(var gradNo in gradientsData.x[gxC]){
                    
                    var grad = gradientsData.x[gxC][gradNo];
                    if(grad.hasOwnProperty("y")){
                        var gdGrad = spawnGradient(gxC, grad.y, ctx, width, height, columnSpacing, rowSpacing, buffer, grad.seed);
                        /*if(grad.coloring == true){
                            */
                               // console.log("rect")
                        if(gdGrad == false){
                             grad = null;
                            continue;
                        } else {
                            ctx.strokeStyle = gdGrad;
                            //console.log(gradientsData.x[gxC])
                            //console.log(grad)
                            ctx.stroke(gradientsData.x[gxC].path)
                        }
                        
                                /*ctx.beginPath();
                                ctx.arc(gxC, 500, 10, 0, 2 * Math.PI);
                                ctx.fillStyle = gdGrad;
                                ctx.fill();*/
                                //ctx.fillRect(25*vw, 25*vh, 50*vw, 50*vh)
                                //ctx.fill()
                            }
                       /* } else {
                            ctx.strokeStyle = "rgba(10, 10, 10, 1)";
                            console.log(grad)
                            ctx.stroke(gradientsData.x[gxC].path)
                        }*/
                        //console.log(gdGrad)
                        
                        //console.log(ctx.strokeStyle)
                        
                        ctx.stroke();
                        grad.y += progression;
                        //console.log(grad.y)
                    }
                }
            
            function generateLine(gxC, gyC, ctx, width, height, columnSpacing, rowSpacing, buffer, gradients){
                //gC stands for groupCoord
                var path = new Path2D()
                path.moveTo(gxC, gyC)
                var iter = 0; //number of iterations so far
                //Start with a slanted line
                
                while(iter * rowSpacing < height+buffer.y){
                    iter++;
                    //Start with a slanted line
                    path.lineTo(gxC + ((iter)*columnSpacing), gyC + ((iter*2 - 1)*rowSpacing))
                    //Straight line down, reuse previous xCOord
                    path.lineTo(gxC + ((iter)*columnSpacing), gyC + ((iter*2)*rowSpacing) + 2)
                    //Write the second upper arm
                    path.moveTo(gxC + ((iter)*columnSpacing), gyC + ((iter*2 - 1)*rowSpacing))
                    path.lineTo(gxC + ((iter + 1)*columnSpacing), gyC + ((iter*2 - 2)*rowSpacing))
                    //return to bottom point
                    path.moveTo(gxC + ((iter)*columnSpacing), gyC + ((iter*2)*rowSpacing) - 1)
                    
                    
                    
                }
                ctx.strokeStyle = "rgba(20, 20, 20, 1)";
                ctx.lineJoin = "rounded"
                ctx.stroke(path);
                gradientsData.x[gxC].path = path;
                //get new array with only applicable gradients
                
                    //console.log("Gradient X detected!")
                    
                
                
                
                
                
                //ctx.stroke()
            }
            function spawnGradient(gxC, currentY, ctx, width, height, columnSpacing, rowSpacing, buffer, seed){
                var currentX = xCalculator(gxC, currentY, columnSpacing, rowSpacing, buffer)
                //out of bounds check to despawn the gradient
                //console.log(`GRADX ${currentX}`)
                if(currentX < width + buffer.x || currentY < height + buffer.y){
                    //console.log(`GRADY ${currentY}`)
                    var gradient = ctx.createRadialGradient(currentX - buffer.x, currentY - buffer.y, 5, currentX - buffer.x, currentY - buffer.y, 300);
                    // gradient.addColorStop(0.8, "#560BAD");
                    gradient.addColorStop(0, "#E2B100");
                    gradient.addColorStop(0.3, "#FDC500");
                    gradient.addColorStop(0.5, "#5C0099");
                    gradient.addColorStop(0.7, "#510087");
                    //if(first == true){
                     //   gradient.addColorStop(1, "rgba(10, 10, 10, 1)")
                    //} else {
                        gradient.addColorStop(1, "rgba(10, 10, 10, 0.1)")
                    //}
                    
                    return gradient;
                } else {
                    return false;
                }
                

            }
            function xCalculator(gxC, y, columnSpacing, rowSpacing, buffer){
                //calculates the x coord of the radial gradient for a given group start coordinate and a y value
                //y value can be negative when its inside the buffer!!
                var currentGroup = Math.floor((y + buffer.y)/(rowSpacing*2))
                //console.log(y)
                //console.log(currentGroup)
                //identify if y is in a straight line or in the slanted line
                var x;
                var localY = ((y + buffer.y) - (currentGroup * rowSpacing * 2))
                //console.log(localY)
                if(localY <= rowSpacing){
                    
                    //slanted line
                    var c = gxC + currentGroup * columnSpacing + buffer.x;
                    var m = columnSpacing / rowSpacing;
                    x = m * (localY - rowSpacing) + c;
                    return x;
                    
                } else {
                    //straight line
                    x = gxC + currentGroup * columnSpacing + buffer.x;
                    return x;
                }
            }
            function uniqueIDGen(){
                return Math.floor(Math.random() * 10000000000)
            }
            animate()
        //console.log()
       // })
        
    
        

    }, [uniqueID])
    
    return (
        <div className="anim-background">
            
                <canvas id={`canvas${uniqueID}`} width="100%" height="92.5vh" style={{position: "absolute"}}/>
                
        </div>
    )
}