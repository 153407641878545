import logo from './logo.svg';
import './App.css';
import Index from "./pages/Index.jsx";
import Portfolio from "./pages/Portfolio.jsx"
import Dashboard from "./pages/Dashboard.jsx"
import CourtPages from "./pages/bookmeacourt/index.js"
import {
  createBrowserRouter,
  RouterProvider,
  useLoaderData,
} from "react-router-dom";
let router = createBrowserRouter([
  {
    path: "/",
    element: <Index />
  },
  {
    path: "/portfolio",
    element: <Portfolio />
  },
  {
    path: "/dashboard",
    element: <Dashboard />
    
  },
  {
    path: "/bookmeacourt/",
    element: <CourtPages.Index />
  },
]);
function App() {
  return <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />;
}

export default App;

