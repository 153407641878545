import React from "react"
import Header from "../../components/header/header.jsx"
import Footer from "../../components/footer/footer.jsx"

function Index(){
    return (
        <div>
            <Header />
            
            <div class="width: 100vw; height:100vh;"><h1>Under Construction</h1></div>
            <Footer/>
           

        </div>

    )
    
}
export default Index;