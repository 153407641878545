import React from "react"
import "./dashboard.css"
import * as THREE from "three"
export default function Dashboard(){
    React.useEffect(() => {
        
            console.log("exec")
            var key = false;
            var submitButton = document.getElementById("submit-button")
            var swapButton = document.getElementById("swap-button")
            var keyArea = document.getElementById("login-key")
            var passwordArea = document.getElementById("login-password")
            var keyInput = document.getElementById("")
            function hash(string) {
            const utf8 = new TextEncoder().encode(string);
            return crypto.subtle.digest('SHA-256', utf8).then((hashBuffer) => {
                const hashArray = Array.from(new Uint8Array(hashBuffer));
                const hashHex = hashArray
                .map((bytes) => bytes.toString(16).padStart(2, '0'))
                .join('');
                return hashHex;
            });
            }
            swapButton.addEventListener("click", () => {
                if(key === false){
                    key = true;
                    passwordArea.style.display = "none";
                    keyArea.style.display = "block";
                    swapButton.innerText = "Have a password instead?"
                } else { 
                    key = false;
                    passwordArea.style.display = "flex";
                    keyArea.style.display = "none";
                    swapButton.innerText = "Have a 8-digit key instead?"
                }
            })

        
        
                    const scene = new THREE.Scene();
                    const camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 1000 );
                    camera.position.set( 0, 0, 200 );
                    camera.lookAt( 0, 0, 0 );
                    const renderer = new THREE.WebGLRenderer();
                    renderer.setSize( window.innerWidth, window.innerHeight);
                    
                    document.getElementById("threejsbackdrop").appendChild( renderer.domElement );
                    renderer.domElement.setAttribute("id", "threejscanvas1")
                    //var materials = [new THREE.MeshBasicMaterial({color: 0xf2ec96}), new THREE.MeshBasicMaterial({color: 0xbdb873}), new THREE.MeshBasicMaterial({color: 0x7a774b})];
                    //console.log(materials)
                    var material = new THREE.MeshPhongMaterial({color: 0x00FFFF, specular: 0xbb40c4, emissive: 0x000000})
                    material.side = THREE.DoubleSide;
                    material.onBeforeCompile = function( shader ) {
                        shader.fragmentShader = shader.fragmentShader.replace(`gl_FragColor = vec4( outgoingLight, diffuseColor.a );`,`gl_FragColor = ( gl_FrontFacing ) ? vec4( outgoingLight, diffuseColor.a ) : vec4( diffuse, opacity );`);
                    };
                    //Convert to degrees from radians
                    var fovDeg = camera.fov * Math.PI / 180;
                    //FOV to backdrop is a triangle!
                    var screenHeight = camera.position.z * Math.tan(fovDeg / 2)
                    var screenWidth = screenHeight * camera.aspect;
                    var worldObjects = {}
                    var limit = 12;
                    var tetraCount = 0;
                    var minBorderDist = 10;
                    var chance = 25;
                    var wireGrad = [0xFFFFFF, 0xFFF9EA, 0xFFF2D6, 0xFFECC1, 0xFFE5AC, 0xFFDF98, 0xFFD883, 0xFFD26F, 0xFFCB5A, 0xFFC545, 0xFFBE31, 0xFFB81C]
                    var interval = 10;
                    /*setTimeout(() => {
                        chance = 35
                    }, 10000)*/
                    const light = new THREE.HemisphereLight( 0xffffbb, 0x080820, 1.3 );
                    //const light = new THREE.DirectionalLight( 0xffffff, 0.5 ); // soft white light
                    scene.add( light );	

                    function random(range){
                        return Math.ceil(Math.random() * range)
                    }
                    function generateTetra(){
                        //radius = size 3-10
                        var geometry = new THREE.OctahedronGeometry(random(2) + 4, 0)
                        
                        //Generate height, width
                        var currentObj = new THREE.Mesh(geometry, material) 
                        
                        // wireframe
                        /*var geo = new THREE.EdgesGeometry( currentObj.geometry ); // or WireframeGeometry
                        //var mat = new THREE.LineBasicMaterial( { color: 0xFFB81C } );
                        var mat = new THREE.LineBasicMaterial( { color: 0x02d0f5 } );
                        var wireframe = new THREE.LineSegments( geo, mat );
                        currentObj.add( wireframe );*/
                        return currentObj;
                    }
                    
                    function spawnTetra(limit){
                        return new Promise((resolve, reject) => {
                            //console.log(Object.keys(worldObjects).length)
                            //console.log(Object.keys(worldObjects.length < limit)
                            if(Object.keys(worldObjects).length < limit){
                                //console.log("spawning")
                                var currentObj = generateTetra();
                                tetraCount++;
                                var rotation = new THREE.Vector3(random(3)/100, random(3)/100, random(3)/100);
                                var movement = (random(5)/10*1) * (camera.position.z / 100);
                                worldObjects[tetraCount] = {tetra: currentObj, rotation: rotation, movement: movement, alt: 0};
                                scene.add(currentObj)
                                var xRange = screenWidth - (minBorderDist * 2);
                                var yRange = screenHeight - (minBorderDist * 2);

                                var xCoord = minBorderDist + random(xRange) - (xRange / 2)
                                var yCoord = minBorderDist + random(yRange) - (yRange / 2)
                                currentObj.position.set(xCoord, yCoord, 0)
                                resolve(currentObj)
                            }
                        })
                    }
                    
                    function updateLayers(){
                        //console.log(worldObjects)
                        //alternater++;
                        for(var no in worldObjects){
                            var x = worldObjects[no]
                            
                            if(x.tetra.position.z > camera.position.z - 4){
                                
                                scene.remove(x.tetra)
                                delete worldObjects[no]
                            } else {
                                x.tetra.position.z += x.movement
                                x.tetra.rotation.x += x.rotation.x
                                x.tetra.rotation.y += x.rotation.y
                                x.tetra.rotation.z += x.rotation.z
                                /*if(x.alt % interval == 0){
                                    var color = wireGrad[Math.abs(x.alt/interval)]
                                    var geo = new THREE.EdgesGeometry( x.tetra.geometry ); // or WireframeGeometry
                                    //var mat = new THREE.LineBasicMaterial( { color: 0xFFB81C } );
                                    var mat = new THREE.LineBasicMaterial( { color: color } );
                                    var wireframe = new THREE.LineSegments( geo, mat );
                                    x.tetra.add(wireframe);
                                    if(x.alt === wireGrad.length * interval){
                                        x.alt = x.alt * -1
                                        console.log("flipping")
                                    }
                                    console.log(color)
                                    
                                }
                                */
                                
                            }
                            //x.alt++;

                        }
                        if(random(chance) == 1 || Object.keys(worldObjects).length < 5){
                            //console.log("spawn command")
                            
                            spawnTetra(7)
                            
                        }
                    }
                    
                    
                    //const geometry = new THREE.BufferGeometry().setFromPoints( points );
                    //const line = new THREE.Line( geometry, material );
                    //scene.add( line );
                    //renderer.render(scene, camera)
                    

                    function animate() {
                        requestAnimationFrame( animate );

                        updateLayers()

                        renderer.render( scene, camera );
                    };

                    animate();
                
    }, [])
    return (
        <div className="main">
            
            <div className="content">
                <div id="threejsbackdrop">
                    
                </div>
                <div className="login-container">
                    <div className="login-area container">
                        <div className="login-password login-component row" id="login-password">
                            <div className="login-field col-6 col-xs-12">
                                <label className="login-label">User ID:   </label><input type="text" placeholder="" maxLength="20"/>
                            </div>
                            <div className="login-field col-6 col-xs-12" style={{"minWidth": "270px"}}>
                                <label className="login-label">Password:   </label><input type="password" placeholder="" maxLength="20"/>
                            </div>
                        </div>
                        <div className="login-key login-component row" id="login-key" style={{display: "none"}}>
                            <div className="login-field col-12 col-xs-12">
                                <label className="login-label">8-digit key:</label><input type="text" placeholder="" maxLength="8"/>
                        
                            </div>
                        </div>
                        <div className="button-area">
                            <div className="button-container">
                                <button className="login-buttons" id="swap-button">Have a 8-digit key instead? Click me!</button>
                                <button className="login-buttons" id="submit-button" style={{"margin": "auto", "marginTop": "20px"}}>Submit</button>
                            </div>
                        </div>
                    </div>
                   
                </div>
                
            </div>
        </div>
    )
}
